<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2" v-if="opt.showTop" />
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div>
        <!-- 这里的状态判断  应该是 待审批，且 tabs 长度为1  -->
        <template v-if="(appStatus == 1 && tabs.length == 1) || (appStatus != 1 && tabs.length == 1 && !showApprove) ">
          <van-list :finished="true" finished-text="没有更多了">
            <van-collapse v-model="activeNames">
              <van-collapse-item v-for="(item, index) in tabs[0].infos" :key="index" :title="item.title" :name="item.name" icon="label" class="vhhg">
                <div v-if="item.cards">
                  <div v-for="(lists, index) in details.mateLists" :key="index" style="margin: 10px; padding: 14px; border-radius: 10px; background-color: #ffffff; margin-top: 0px;">
                    <div v-for="(inf, idx) in item.lists" :key="idx">
                      <div v-if="inf.cardsTitle" style="display: flex; justify-content: space-between; margin-bottom: 12px;">
                        <div style="font-weight: 700; line-height: 24px; color: #333333; ">{{`${lists[inf.value]}`}}</div>
                        <div style="display: flex; align-items: center;">
                          <div v-if="$route.params.pages == 'protocol'" style="color: #333333">
                            <div v-if="lists.projectChangeType == '新增'" style="display: flex; align-items: center;">
                              <div style="background-color: #4BCED0; border-radius: 3px; width: 6px; height: 6px; margin-right: 8px"></div>
                              新增
                            </div>
                            <div v-if="lists.projectChangeType == '冻结'" style="display: flex; align-items: center;">
                              <div style="background-color: #FB6260; border-radius: 3px; width: 6px; height: 6px; margin-right: 8px"></div>
                              冻结
                            </div>
                          </div>
                          <div v-if="$route.params.pages == 'saving'" style="width: 75px; display: flex; align-items: center; justify-content: flex-end">
                          <van-checkbox-group v-model="result" icon-size="16px" ref="checkboxGroup" style="display: flex; align-items: center;">
                            <div style="color: #FCBE53; margin-right: 8px">驳回</div>
                            <van-checkbox
                              :name="lists.id"
                              class="check-box"
                              @click="checkboxFun"
                            ></van-checkbox>
                          </van-checkbox-group>
                          </div>
                        </div>
                      </div>
                      <div v-else style="display: flex; align-items: center;">
                        <div style="line-height: 24px">{{inf.title}}：{{`${lists[inf.value] || ''}`}}</div> <span style="margin-left: 8px; font-size: 12px; color: #027DB4;" @click="jumpToHistory(lists)" v-if="inf.title === '调整前节约率（%）'">历史调整记录</span>
                      </div>
                    </div>
                  </div>
                  <!-- 物流附件 -->
                  <div v-if="$route.params.pages.match('logistics') !== null">
                    <van-cell-group class="group">
                      <van-cell v-for="(item, index) in details.wlProjectfiles" :key="index" title="物流方案附件">
                        <div>
                          <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                            {{ item.title }}
                          </a>
                          <span v-else @click="onCompression">{{item.title}}</span>
                        </div>
                      </van-cell>
                    </van-cell-group>
                  </div>
                </div>
                <!-- 监造相关页面的插入式附件 -->
                <div v-else-if="item.insert">
                  <div v-for="sf in details.schemeFiles" :key="sf.type">
                    <van-cell-group class="group">
                      <van-cell title="类型" :value="sf.type"></van-cell>
                      <van-cell title="文件名" style="margin-bottom: 6px;">
                        <template #default>
                          <div v-for="(item, index) in sf.files" :key="index">
                            <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                              {{ item.title }}
                            </a>
                            <span v-else @click="onCompression">{{item.title}}</span>
                          </div>
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </div>
                </div>
                <van-cell-group class="group" v-else>
                  <div v-if="item.change">
                    <van-cell-group class="group">
                      <van-cell title="变更理由" :value="details.changeReason || details.remark"></van-cell>
                      <van-cell title="附件" v-for="(item, index) in details.changeFile" :key="index" style="margin-bottom: 6px;">
                        <template #default>
                          <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                            {{ item.title }}
                          </a>
                          <span v-else @click="onCompression">{{item.title}}</span>
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </div>
                  <div v-else>
                    <van-cell v-for="(list, index) in item.lists" :key="index" :title="list.title" :value="`${list.to ? '查看' : details[list.value] || ' ' }`" @click="toSecondDetail(list.to, list.key)" />
                  </div>
                </van-cell-group>
              </van-collapse-item>

              <van-collapse-item :title="filesTitle || '附件'" icon="label" v-show="showFiles" name="15">
                <van-cell-group class="group">
                  <van-cell v-for="(item, index) in attachement" :key="index" :title="item.fileCodename">
                    <div>
                      <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                        {{ item.title }}
                      </a>
                      <span v-else @click="onCompression">{{item.title}}</span>
                    </div>
                  </van-cell>
                  <van-cell v-if="details.remark" :title="'备注'" :value="details.remark" />
                </van-cell-group>
              </van-collapse-item>
            </van-collapse>
          </van-list>
        </template>

        <template v-else>
          <van-tabs v-model="active" :animated="true">
            <van-tab :title="tab.label" :name="tab.name" v-for="tab in tabs" :key="tab.idx">
              <van-list :finished="true" finished-text="没有更多了">
                <van-collapse v-model="activeNames">
                  <van-collapse-item v-for="(item, index) in tab.infos" :key="index" :title="item.title" :name="item.name" icon="label" class="vhhg">
                    <div v-if="item.cards">
                      <div v-for="(lists, index) in details.mateLists" :key="index" style="margin: 10px; padding: 14px; border-radius: 10px; background-color: #ffffff; margin-top: 0px;">
                        <div v-for="(inf, idx) in item.lists" :key="idx">
                          <div v-if="inf.cardsTitle" style="display: flex; justify-content: space-between; margin-bottom: 12px;">
                            <div style="font-weight: 700; line-height: 24px; color: #333333; ">{{`${lists[inf.value]}`}}</div>
                            <div style="display: flex; align-items: center;">
                              <div v-if="$route.params.pages == 'protocol'" style="color: #333333">
                                <div v-if="lists.projectChangeType == '新增'" style="display: flex; align-items: center;">
                                  <div style="background-color: #4BCED0; border-radius: 3px; width: 6px; height: 6px; margin-right: 8px"></div>
                                  新增
                                </div>
                                <div v-if="lists.projectChangeType == '冻结'" style="display: flex; align-items: center;">
                                  <div style="background-color: #FB6260; border-radius: 3px; width: 6px; height: 6px; margin-right: 8px"></div>
                                  冻结
                                </div>
                              </div>
                              <div v-if="$route.params.pages == 'saving'" style="width: 75px; display: flex; align-items: center; justify-content: flex-end">
                                <div v-if="lists.valid === 0" style="color: #FCBE53; margin-right: 8px">已驳回</div>
                              </div>
                            </div>
                          </div>
                          <div v-else style="display: flex; align-items: center;">
                            <div style="line-height: 24px">{{inf.title}}：{{`${lists[inf.value] || ''}`}}</div> <span style="margin-left: 8px; font-size: 12px; color: #027DB4;" @click="jumpToHistory(lists)" v-if="inf.title === '调整前节约率（%）'">历史调整记录</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="$route.params.pages.match('logistics') !== null">
                        <van-cell-group class="group">
                          <van-cell v-for="(item, index) in details.wlProjectfiles" :key="index" title="物流方案附件">
                            <div>
                              <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                                {{ item.title }}
                              </a>
                              <span v-else @click="onCompression">{{item.title}}</span>
                            </div>
                          </van-cell>
                        </van-cell-group>
                      </div>
                    </div>
                    <div v-else-if="item.insert">
                      <div v-for="sf in details.schemeFiles" :key="sf.type">
                        <van-cell-group class="group">
                          <van-cell title="类型" :value="sf.type"></van-cell>
                          <van-cell title="文件名" style="margin-bottom: 6px;">
                            <template #default>
                              <div v-for="(item, index) in sf.files" :key="index">
                                <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                                  {{ item.title }}
                                </a>
                                <span v-else @click="onCompression">{{item.title}}</span>
                              </div>
                            </template>
                          </van-cell>
                        </van-cell-group>
                      </div>
                    </div>
                    <van-cell-group class="group" v-else>
                      <div v-if="item.change">
                        <van-cell-group class="group">
                          <van-cell title="变更理由" :value="details.changeReason"></van-cell>
                          <van-cell title="附件" v-for="(item, index) in details.changeFile" :key="index" style="margin-bottom: 6px;">
                            <template #default>
                              <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                                {{ item.title }}
                              </a>
                              <span v-else @click="onCompression">{{item.title}}</span>
                            </template>
                          </van-cell>
                        </van-cell-group>
                      </div>
                      <div v-else>
                        <van-cell v-for="(list, index) in item.lists" :key="index" :title="list.title" :value="`${list.to ? '查看' : details[list.value] || ' '}`" @click="toSecondDetail(list.to, list.key)" />
                      </div>
                    </van-cell-group>
                  </van-collapse-item>

                  <van-collapse-item :title="filesTitle || '附件'" icon="label" v-show="showFiles" name="15">
                    <van-cell-group class="group">
                      <van-cell v-for="(item, index) in attachement" :key="index" :title="item.fileCodename">
                        <div>
                          <a v-if="!filesType(item)" :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title">
                            {{ item.title }}
                          </a>
                          <span v-else @click="onCompression">{{item.title}}</span>
                        </div>
                      </van-cell>
                    </van-cell-group>
                  </van-collapse-item>
                </van-collapse>
              </van-list>
            </van-tab>
            <van-tab title="审批记录" name="d" v-if="appStatus != 1 && showApprove">
              <div
                class="record"
                v-for="(item, index) in details.wfapproveinfos"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                  <van-cell title="审批结论" :value="item.resultType"></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-tab>
          </van-tabs>
        </template>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog, Toast} from "vant"

export default {
  data() {
    return {
      opt: {}, // 当前页面参数
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"],
      supplierList: [],
      details: [],
      levels: null,
      hierarchy: "",

      showFiles: true, // 是否显示附件 折叠
      attachement: [], // 统一的附件名称
      result: [],


      basicTop: {}, // 配置文件 - 页头 一般三个状态都是一样的

      preAuditJson: [],

      // 页面数据
      tabs: [],

      filesTitle: '',
      showApprove: false,
    }
  },
  async created() {
    let route = this.$route.params.pages
    let opt = this.$layouts[route]
    // 路由表中没有则跳404
    if (!opt) {
      this.$router.replace({ name: "notFound" })
      return
    }

    this.opt = opt
    this.basicTop = opt.basicTop
    await this.getOfferDetail()

    let specialPages = ['logistics', 'logisticsChange', 'logisticsConfirm', 'scheme', 'schemeChange', 'schemeConfirm']

    let includesPage = specialPages.find(item => item === route)

    if (includesPage) {
      // 包含该页面，调用自动生成的方法
      this.tabs = opt.geneTabs(this.details)
    } else {
      // 不包含，调用opt中数据
      this.tabs = opt.tabs
    }
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id
    },
    appStatus() {
      return this.$route.query.appStatus
    },
  },
  methods: {
    async getOfferDetail() {
      let query = this.$route.query
      let mates

      let pages = this.$route.params.pages

      // 获取参数
      let par = this.opt.detailParam(query)
      // 请求数据
      let data = await this.$api.ec[`${this.opt.detailApi}`](par);

      if (this.opt.detailMateApi) {
        mates = await this.$api.ec[`${this.opt.detailMateApi}`](par)
        console.log('mate', mates)
      }

      if (data) {
        this.loadingfalg = false
        data = this.opt.fillingDetail(data)

        if (pages == 'saving') {
          this.preAuditJson = data.preAuditJson
          this.filesTitle = "附件与备注"
        }

        this.title = data.pageTitle // 页面标题

        // 监造相关页面
        if (pages.match('scheme') !== null) {
          this.showFiles = false
        } else {
          this.showFiles = data.files && data.files.length > 0 ? true : false // 是否显示附件
        }

        this.attachement = data.files // 附件更名
        this.showApprove = data.hideApprove && data.hideApprove === '1' ? false : true // 是否展示审批记录

        // 合并物料
        if (mates) {
          data.mateLists = mates.data
        }

        this.details = data
        this.basicTop.data = data

        data.wfnodeVOs && data.wfnodeVOs.map((item, index) => {
          if (item.isNodeaction == 1) {
            this.levels = index + 1
            return
          }
        })
        this.hierarchy = data.wfnodeVOs && data.wfnodeVOs.length // 步骤条参数
      }
    },

    jumpToHistory(val) {
      let query = { agreement: val.agreement }
      this.$router.push({ name: 'savingHistory', query })
    },

    checkboxFun () {
      console.log('选择内容', this.result)
    },

    // NOTE 判断附件是否为压缩包
    filesType(file) {
      let type = file.title.split('.').reverse()[0]
      let typeEnum = {'zip': true, 'rar': true, '7z': true}
      return typeEnum[type] || false
    },

    toSecondDetail(to, key) {
      if (!to) {
        return
      } else {
        console.log('to', to, key, this.details[key])

        let query = {
          projectId: this.details[key]
        }

        this.$router.push({ name: to, query })
      }
    },

    ondown(item) {
      let query = {
        id: item.id,
      }
      this.$router.push({ name: "preview", query })
    },

    async auditApply(o, message) {
      let params
      if (o == 2) {
        this.submitflag = true
        params = {
          audit: 0,
          leadexam: message,
        }
      } else {
        params = {
          audit: 1,
          leadexam: "同意",
        }
      }

      let quer = this.$route.query
      let opts = [this.details, this.preAuditJson, this.result]

      let appendParam = this.opt.detailApproveParam(quer, opts)
      Object.assign(params, appendParam)

      let data = await this.$api.ec[`${this.opt.detailApproveApi}`](params)
      // let path = this.opt.listPage

      if (data) {
        Toast.success("提交成功")
        setTimeout(() => {
          this.$router.go(-1)
          // this.$router.push({ path: path, query: {type: 1} })
        }, 1000)
      }
    },

    onClickLeft() {
      this.$router.go(-1)
      // if (this.showage) {
      //   // this.$router.go(-1)
      //   this.$router.push({ name: "ecCompetitiveOfferStageTwo", query: {type: 1} })
      // } else {
      //   this.showage = true
      //   this.title = "采购方案审批详情"
      // }
    },
    onRefresh() {
      this.loading = true
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      })
    },
    onLoad() {},
    listDetails() {},
    agree() {
      Dialog.confirm({ title: "审批", message: "是否同意" }).then(() => {
        this.show = false
        this.auditApply()
      })
      .catch(() => {
        // on cancel
      })
    },
    noagree() {
      this.title = "审批"
      this.showage = false
    },
  },
}
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
  background-color: #ffffff00;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>
